import styled from "styled-components";
import TypewriterComponent from "typewriter-effect";


interface Props {
    displayWords: string[]
}

const H1 = styled.h1`
    font-size: 30px;
    font-family: "Geist Bold",serif;
`
const MB_BR = styled.br`
    @media screen and (min-width: 480px){
        display: none;
    }
`


function MainText(props: Props) {

    const style = {
        background: 'linear-gradient(to right, #7C63DD, #19A0EB, #00E4C7)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontSize: '30px',
        fontFamily: "Geist Bold",
    };

    return (
        <div style={{marginBottom: "20px"}}>
            <H1>A</H1>
            <h1 style={style}>
            <TypewriterComponent
                options={{
                    strings: props.displayWords,
                    autoStart: true,
                    loop: true,
                    delay: 90,
                    deleteSpeed: 50,
                }}
            />
        </h1>
            <H1>
            all-in-one <MB_BR /> Trading Platform
            </H1>
        </div>

    );
}

export default MainText;
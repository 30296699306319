import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
    text: string;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
}

const AnimatedGradient = keyframes`
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
`;

const Button = styled.button`
    border: none;
    color: white;
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
    padding: 10px;
    border-radius: 35px;
    cursor: pointer;

    background: linear-gradient(45deg, #05D9CD, #1AA1EB, #7966DE, #229BEA, #08D1D2);
    background-size: 300% 300%;
    animation: ${AnimatedGradient} 3s linear infinite;
`;

const GradientBtn = (props: Props) => (
    <Button type={props.type} onClick={props.onClick}>
        {props.text}
    </Button>
);

export default GradientBtn;


import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    background-color: #090909;
    color: #fff;
    padding: 20px;
    text-align: center;
`;

const SocialContainer = styled.div`
  margin-top: 15px;
`;

const SocialLink = styled.a`
  margin: 0 10px;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const MarginBottom = styled.div`
    margin-bottom: 15px;
    `;

const Footer = () => {
    return (
        <FooterContainer>
            <SocialLink href="https://www.instagram.com/abovetechapp" target="_blank" rel="noopener noreferrer">
                Instagram
            </SocialLink>
            <SocialLink href="https://discord.gg/above" target="_blank" rel="noopener noreferrer">
                Discord
            </SocialLink>
            <SocialLink href="https://twitter.com/AboveTechApp" target="_blank" rel="noopener noreferrer">
                Twitter
            </SocialLink>
            <MarginBottom>
            </MarginBottom>
            <p>© 2023 Above. All rights reserved.</p>
            <SocialContainer>
            </SocialContainer>
        </FooterContainer>
    );
};

export default Footer;

import React from 'react';
import logo from '../assets/logo.png';
import styled from 'styled-components';
import GradientBtn from './GradientBtn';


const HeaderMainDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    @media screen and (max-width: 480px) {
        height: 5vh;
        margin-bottom: 20px;
    }
    @media screen and (min-width: 480px) {
        height: 8vh;
        margin-bottom: 60px;
    }
`;

const LinkDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
`;

const Logo = styled.img`
    max-height: 50%;
    
`;

const twitter = styled.div`
    margin-right: 10px;
    cursor: pointer;
    font-family: 'Manrope', sans-serif;
`;

const Header = () => {


    const discordLink = () => {
        console.log("Discord link clicked");
        window.open("https://discord.gg/above")
    }

    const twitterLink = () => {
        console.log("Twitter link clicked");
        window.open("https://twitter.com/AboveTechApp")
    }

    return (
        <HeaderMainDiv>
            <Logo src={logo} />
            <LinkDiv>
                <div onClick={twitterLink} style={{ marginRight: '10px', cursor: "pointer" }}>Twitter</div>
                <GradientBtn text="Discord" onClick={discordLink} />
            </LinkDiv>
        </HeaderMainDiv>
    );
};

export default Header;

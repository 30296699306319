import Spline from '@splinetool/react-spline';
import React from 'react';
import styled from 'styled-components';

interface Props{
    splineURL: string,
    className: string
}


const SplineContainer = styled.div`
    align-items: center;
`

const SplineComp = (props: Props) => {

    return (
        <SplineContainer className={props.className}>
            <Spline scene={props.splineURL}></Spline>
        </SplineContainer>
    )
}


export default SplineComp;
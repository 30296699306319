import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
// @ts-ignore
import { ResponsiveImage, ResponsiveImageSize } from 'react-responsive-image'
import ReactParallaxTilt from "react-parallax-tilt";

type Props = {
    // children: string | JSX.Element | JSX.Element[],
    srcMobile: string,
    srcDesktop: string,
    altText: string,
    headingText: string,
    description: string
}

const OutlineDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    width: 75%;
    @media screen and (max-width: 480px){
        padding: 5%;
        min-width: 90%;
    }
    @media screen and (min-width: 480px){
        padding: 5%;
        min-width: 90%;
    }
    
    > img {
        @media screen and (max-width: 480px){
            min-width: 90%;
        }
        @media screen and (min-width: 480px){
            min-width: 90%;
        }
    }
`

const LeftAlignDiv = styled.div`
    width: 100%;
`

const Heading = styled.h2`
    font-family: ${props => props.theme.fontFamily.manrope};
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
`
const Description = styled.p`
    font-family: ${props => props.theme.fontFamily.manrope};
    font-size: 12px;
    margin-bottom: 20px;
`

const OutlineCard = (props: Props) => {


    return (
        <ReactParallaxTilt>
            <OutlineDiv style={{background: "linear-gradient(130deg, rgba(17,17,17,1) 0%, rgba(0,0,0,0) 100%)"}}>
                <ResponsiveImage>
                    <ResponsiveImageSize
                        default
                        minWidth={0}
                        path={props.srcMobile}
                    />
                    <ResponsiveImageSize
                        default
                        minWidth={480}
                        path={props.srcDesktop}
                    />
                </ResponsiveImage>
                <LeftAlignDiv>
                    <Heading>{props.headingText}</Heading>
                    <Description>{props.description}</Description>
                </LeftAlignDiv>
            </OutlineDiv>
        </ReactParallaxTilt>

    )
}


export default OutlineCard;
import React from 'react';
import styled from 'styled-components';

type Props = {
    children: string | JSX.Element | JSX.Element[],
    padding: {
        mobile: number[]
        desktop: number[]
    }
    minHeight?: string
}

const CardDiv = styled.div<{$padding?: any, $minHeight?: string}>`
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 30px;

        @media screen and (max-width: 480px) {
            padding: ${props => props.$padding.mobile[0]}% ${props => props.$padding.mobile[1]}% ${props => props.$padding.mobile[2]}% ${props => props.$padding.mobile[3]}%;
            min-width: 90%;
            min-height: ${props => props.$minHeight};
        }
        @media screen and (min-width: 480px) {
            padding: ${props => props.$padding.desktop[0]}% ${props => props.$padding.desktop[1]}% ${props => props.$padding.desktop[2]}% ${props => props.$padding.desktop[3]}%;
        }

    `
function Card({children, padding, minHeight}: Props) {



    return (
        <CardDiv $padding={padding} $minHeight={minHeight} className={"card-background"}>
            {children}
        </CardDiv>
    );
}

export default Card;
import React from 'react';
import './App.css';
import styled, {ThemeProvider} from 'styled-components';
import Card from './components/Card';
import MainText from './components/MainText';
import Header from './components/Header';
import backgroundImg from './assets/desktop-background.png'
import backgroundImgMobile from './assets/mobile-background.png'
import InputWithButton from './components/InputWithButton';
import SplineComp from './components/SplineComp';
import Lottie from 'lottie-react';
import breeze from './assets/breeze.svg'
import atlas from './assets/atlas.svg'
import comingSoon from './assets/comingsoon.svg'
import eth from './assets/Eth.json'

import {LinearGradient} from 'react-text-gradients';
import OutlineCard from './components/OutlineCard';
import tradeWithAbove from './assets/Border animation.json'
//features image imports

import portfolioMobile from './assets/features/portfolio-mobile.svg';
import portfolioDesktop from './assets/features/portfolio-desktop.svg';

import tickersMobile from './assets/features/tickers-mobile.png';
import tickersDesktop from './assets/features/tickers-desktop.png';

import candleChartMobile from './assets/features/candlechart-mobile.svg';
import candleChartDesktop from './assets/features/candlechart-desktop.svg';

import orderLimitMobile from './assets/features/orderlimit-mobile.svg';
import orderLimitDesktop from './assets/features/orderlimit-desktop.svg';
import Footer from './components/Footer';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";






const theme = {
    colors: {
        "black": "#090909",
        "light-blue": "#91B9FF",
        "gray": "#858585",
        "cardBackground": "#1A1A1A"
    },
    fontFamily: {
        "Helvetica": "Helvetica, sans-serif",
        "SFPro": "SF Pro Display, sans-serif",
        'hanson': ['Hanson', 'sans-serif'],
        'manrope': "Manrope, sans-serif"
    },
    gradientColorStops: {
        btnGradient: [],
    },
}

const Main = styled.div`
    background-color: ${props => props.theme.colors.black};

    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 480px) {
        padding: 0 20px 0 20px;
        background-image: url(${backgroundImgMobile});
    }
    @media screen and (min-width: 480px) {
        padding: 0 15%;
        background-image: url(${backgroundImg});
    }

    color: white;
`

const Section = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 480px) {
        flex-wrap: nowrap;
        flex-direction: column;
    }

`

const FirstSection = styled.div`

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 480px) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 20px;
        margin-bottom: 40px;
    }
`

const SecondSection = styled.div`

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 480px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        margin-bottom: 60px;
    }
`

const ThirdSection = styled.div`

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 480px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }
`

const SectionTitle = styled.h1`
    margin-bottom: 20px;
    font-family: ${props => props.theme.fontFamily.manrope};
    font-size: 20px;
    font-weight: 700;
`

const SectionSubHeading = styled.h2`
    font-family: ${props => props.theme.fontFamily.manrope};
    color: #949494;
    text-align: center;
    font-size: 20px;
`

const MarginBelow = styled.div`
    //height: 10px;
    //width: 10px;
    margin-bottom: 10px;
`

const MarginBelowLg = styled.div`
    //height: 10px;
    //width: 10px;
    margin-bottom: 25px;
`

const BR_SM = styled.br`
    @media screen and (min-width: 480px) {
        display: none;
    }
`

const GradientTitles = styled.h2`
    margin-bottom: 20px;
    font-family: ${props => props.theme.fontFamily.manrope};
    font-size: 25px;
    font-weight: bold;
`

const Column = styled.div`
    width: 100%;
    @media screen and (min-width: 480px) {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
    }
`


const GlobeMobile = styled.div`
    @media screen and (min-width: 480px) {
        display: none;
    }
`

const GlobeSmallDesktop = styled.div`
    display: none;
    @media (min-width: 481px) and (max-width: 1600px){
        display: block;
    }
`


const GlobeDesktop = styled.div `
    display: none;
    @media screen and (min-width: 1601px) {
        display: block;
    }
`

function App() {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={"6LeByzwpAAAAAL8h9z71Fya0drzaffyPHiwUhNxE"}
        >
        <ThemeProvider theme={theme}>
            <Main>
                <Header></Header>
                <FirstSection>
                    <Column>
                        <MainText displayWords={["REVOLUTIONARY", "POWERFUL", "VISIONARY"]}/>
                        <Card padding={{mobile: [5, 5, 0, 5], desktop: [5, 5, 0, 5]}}>
                            <SectionTitle style={{color: "#949494"}}>Everywhere. Every Moment.</SectionTitle>
                            <InputWithButton placeholderText="Email Address"
                                             buttonText="Join Waitlist"></InputWithButton>
                            <MarginBelow/>
                            <GlobeMobile>
                                <SplineComp className="globe-mobile" splineURL="https://prod.spline.design/QAGxlemkkQwYcMsC/scene.splinecode"></SplineComp>
                            </GlobeMobile>

                            <GlobeDesktop>
                                <SplineComp className="globe-desktop"  splineURL="https://prod.spline.design/2pBr4Shk7DuJeSS5/scene.splinecode"></SplineComp>
                            </GlobeDesktop>

                            <GlobeSmallDesktop>
                                <SplineComp className="globe-small-desktop" splineURL="https://prod.spline.design/MyLhkG44m-jpwk-u/scene.splinecode"></SplineComp>
                            </GlobeSmallDesktop>
                        </Card>
                    </Column>
                    <Card padding={{mobile: [5, 5, 10, 5], desktop: [1, 0, 0, 0]}}>
                        <MarginBelowLg/>
                        <MarginBelowLg/>
                        <SectionTitle>Trade with Above</SectionTitle>
                        <Lottie animationData={tradeWithAbove}/>
                    </Card>
                </FirstSection>
                <Section>
                    <SectionSubHeading>At the forefront of trading <BR_SM/>innovation, we <br></br>
                        offer two tailored options for trades of all levels.</SectionSubHeading>
                </Section>
                <SecondSection>

                    <Card padding={{ mobile: [5, 5, 15, 5], desktop: [5, 5, 15, 5] }}>
                        <MarginBelowLg/>
                        <MarginBelowLg/>

                        <div  style={{ width: '200px' }}>
                            {/* Replace 'img' with your image source */}
                            <img className="atlas" src={atlas} alt="Atlas" style={{ width: '100%' }} />
                        </div>
                        <MarginBelowLg/>

                        <GradientTitles>
                            <LinearGradient gradient={['to left', '#7966DE, #1D9EEB']}>Atlas</LinearGradient>
                        </GradientTitles>
                        <SectionSubHeading>
                            Map out your advanced trading journey. Atlas, your trading GPS.
                        </SectionSubHeading>
                    </Card>
                    <Card padding={{ mobile: [5, 5, 15, 5], desktop: [5, 5, 15, 5] }}>
                        <MarginBelowLg/>
                        <MarginBelowLg/>

                        <div style={{ width: '200px' }}>
                            <img className={"breeze"} src={breeze} alt="Breeze Animation" style={{ width: '100%' }} />
                        </div>
                        <MarginBelowLg/>
                        <GradientTitles>
                            <LinearGradient gradient={['to left', '#098E87, #1AA0EB']}>Breeze</LinearGradient>
                        </GradientTitles>
                        <SectionSubHeading>
                            Effortless trading. Powerful results. Breeze, the key to your financial ease.
                        </SectionSubHeading>
                    </Card>

                    <Card padding={{ mobile: [5, 5, 0, 5], desktop: [5, 5, 0, 5] }}>
                        <MarginBelowLg/>
                        <MarginBelowLg/>

                        <div style={{ width: '200px' }}>
                            <img className={"coming-soon"} src={comingSoon} alt="Coming Soon Animation" style={{ width: '100%' }} />
                        </div>
                        <MarginBelowLg/>

                        <GradientTitles style={{ color: '#949494', fontWeight: 'normal' }}>
                            Coming Soon.
                        </GradientTitles>
                    </Card>
                </SecondSection>
                <Section style={{marginBottom: "50px"}}>
                    <SectionTitle style={{fontSize: "24px"}}>True all-in-one solution</SectionTitle>
                        <Lottie  animationData={eth} />
                </Section>
                <Section>
                    <SectionTitle>Features</SectionTitle>
                    <ThirdSection>
                        <OutlineCard srcMobile={portfolioMobile} srcDesktop={portfolioDesktop} altText="portfolio"
                                     headingText="Portfolio"
                                     description="Experience the absolute simplest method to seamlessly manage your portfolio"/>
                        <OutlineCard srcMobile={candleChartMobile} srcDesktop={candleChartDesktop} altText="Mirror Bot"
                                     headingText="Mirror Bot" description="Supercharge your portfolio by mirroring
the strategies of the market’s elite traders"/>
                        <OutlineCard srcMobile={tickersMobile} srcDesktop={tickersDesktop} altText="Alerts"
                                     headingText="Alerts" description="Stay ahead of the curve with real-time contract
updates and portfolio alerts that keep you in
complete control of your trades"/>
                        <OutlineCard srcMobile={orderLimitMobile} srcDesktop={orderLimitDesktop} altText="Limit Orders"
                                     headingText="Limit Orders" description="Automate your trades with clarity - without the
stress of volatile markets"/>
                    </ThirdSection>
                </Section>
                <Footer/>
            </Main>
        </ThemeProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;

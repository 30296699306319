import React, {useCallback, useState} from 'react';
import GradientBtn from './GradientBtn';
import styled from 'styled-components';
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";

interface Props {
    placeholderText: string,
    buttonText: string
}

const MarginBelowLg = styled.div`
    margin-bottom: 25px;
`

const Form = styled.form`
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    padding: 5px 5px 5px 15px;
    border-radius: 52px;
`

const Input = styled.input`
    flex-grow: 2;
    border: none;
    background-color: transparent;
    color: white;

    &:active, &:focus {
        border: none;
        outline-width: 0;
    }
`

const SuccessMessage = styled.div`
    color: #77dd77;
    margin-top: 10px;
    text-align: center;
    
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;


const ErrorMessage = styled.div`
    color: #ff6961;
    margin-top: 10px;
    text-align: center;
    
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;




// Function for validating the email format
function validateEmail(email: string) : boolean {
    let regexp = new RegExp("^([a-z0-9._%+-]+)@([a-z0-9.-]+)\.([a-z]{2,3})$");
    return regexp.test(email);
  }

function InputWithButton(props: Props): JSX.Element {
    const[token, setToken] = useState<string>("")
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const onVerify = useCallback((token: React.SetStateAction<string>) => {
        setToken(token)

    },[])

    const doSomething = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // validate email here
        if (!validateEmail(email)) {
            alert("Invalid email format");
            return;
        }

        axios.post('https://9ibhfc0rrf.execute-api.us-east-1.amazonaws.com/Develop/signup', {
            "reCaptchaToken": token,
            "email": email
        }).then((response) => {
            const res = response.data;
            console.log(res)
            setSubmitted(true); // Update the submission status
        }).then().catch((error) => {
            console.log(error);
            setError(true); // Update the error status
        });

        console.log(token)
        setRefreshReCaptcha(r => !r);
    }

    const handleEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(e.target.value);
    }


    return (
        <>
            <div>
                <Form onSubmit={doSomething}>
                    <Input name="email" type="email" placeholder={props.placeholderText} onChange={handleEmailChange}/>
                    <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha}/>
                    <GradientBtn type={"submit"} text={props.buttonText}></GradientBtn>
                </Form>

            </div>

            <div>
                {submitted && <SuccessMessage>We have received your email.</SuccessMessage>}
                {error && <ErrorMessage>There was an error. Please try again later.</ErrorMessage>}

            </div>
        </>


    )
}

export default InputWithButton;
